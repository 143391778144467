/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"


export const Temperature = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])

  

    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition 
            classNames="slide" 
            timeout={3000}>
        <div>
        <Flex sx={{width: "100%", justifyContent: "center"}}>
          <Styled.h2>Do you sleep hot or cold?</Styled.h2>
        </Flex>
        <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="cold"
                name="temperature"
                type="radio"
                value="cold"
                checked={values.temperature === "cold" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="cold" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
        
                  <span>Cold</span>
                </Flex>
              </label>
  
      
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="hot"
                name="temperature"
                type="radio"
                value="hot"
                checked={values.temperature === "hot" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="hot"sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
        
                  <span>Hot</span>
                </Flex>
              </label>
    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="neither"
                name="temperature"
                type="radio"
                value="neither"
                checked={values.temperature === "neither" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="neither"sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
           
                  <span>Just Right</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="idk"
                name="temperature"
                type="radio"
                value="idk"
                checked={values.temperature === "idk" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="idk"sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
          
                  <span>Not sure</span>
                </Flex>
              </label>
        

        </Grid>
        </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) 
      
}