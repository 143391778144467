/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"

export const Comfort = ({step, order, handleChange, values}) => {
  
  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>
        <Flex sx={{width: "100%", justifyContent: "center"}}>
          <Styled.h2>What's your ideal comfort?</Styled.h2>
        </Flex>
        <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="firm"
                name="comfort"
                type="radio"
                value="firm"
                checked={values.comfort === "firm" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="firm" sx={{textAlign: "center"}} sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
 
                  <span>Firm</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="medium"
                name="comfort"
                type="radio"
                value="medium"
                checked={values.comfort === "medium" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="medium" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
  
                  <span>Medium</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="plush"
                name="comfort"
                type="radio"
                value="plush"
                checked={values.comfort === "plush" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="plush" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
 
                  <span>Plush</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="idk"
                name="comfort"
                type="radio"
                value="idk"
                checked={values.comfort === "idk" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="idk" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
 
                  <span>No idea</span>
                </Flex>
              </label>
        

        </Grid>
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
      
}