import React from "react"
import { QuizContainer } from "../components/Quiz"
import SEO from "../components/seo"

import "../styles/global.css"




const SleepQuiz = () => {



  return(
    <>
      <SEO title="Sleep Quiz" description="This is the Sleep N Snooze Sleep Quiz" />
      <QuizContainer />
    </>
     

  )
}


export default SleepQuiz