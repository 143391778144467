/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"


export const Concerns = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>
        <Flex sx={{width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
          <Styled.h2 sx={{my: "0"}}>Do you experience any sleep disruptions?</Styled.h2>
          <Styled.h6 sx={{fontWeight: "300", mt: 4}}>These disruptions could be snoring, sleep apnea, acid reflux, allergies, or tossing and turning.</Styled.h6>
        </Flex>
         <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="yes"
                name="concerns"
                type="radio"
                value="yes"
                checked={values.concerns === "yes" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="yes" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>

                  <span>Yes</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="no"
                name="concerns"
                type="radio"
                value="no"
                checked={values.concerns === "no" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="no" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>No</span>
                </Flex>
              </label>

              <input
                sx={{display: "none"}}
                className="checkbox"
                id="sometimes"
                name="concerns"
                type="radio"
                value="sometimes"
                checked={values.concerns === "sometimes" ? true : false}
                onChange={handleChange}
              />

              <label htmlFor="sometimes" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Sometime</span>
                </Flex>
              </label>


              <input
                sx={{display: "none"}}
                className="checkbox"
                id="idk"
                name="concerns"
                type="radio"
                value="idk"
                checked={values.concerns === "idk" ? true : false}
                onChange={handleChange}
              />

              <label htmlFor="idk" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Not sure</span>
                </Flex>
              </label>
  


        </Grid>
        </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  ) 
      
}