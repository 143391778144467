/** @jsx jsx */
import { jsx, Flex, Styled, Button} from "theme-ui"
import {Link} from "gatsby"
import React, {useState} from "react"
import logo from '../../images/sleepnsnooze-logo.svg'
import { Aches, Comfort, Name, Persona, Concerns, Results, SleepType, Temperature, Weight, Email } from "."


export const QuizContainer = () => {

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    sleeptype: "",
    temperature: "",
    comfort: "",
    room: "",
    aches: "",
    persona: "",
    email: "",
    height: "",
    weight: "",

  })
  console.log(values)

  const stepCount = 10
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    const{name, value} = event.target
    setValues({...values, [name]: value})
  }


  const next = () => {
    let currentStep = step
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 9 ? 10 : currentStep + 1
    setStep(currentStep)
  }
    
  const prev = () => {
    let currentStep = step
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    setStep(currentStep)
  }


  // The "next" and "previous" button functions
const previousButton =() =>{
  let currentStep = step;
  // If the current step is not 1, then render the "previous" button
  if(currentStep !== 1){
    return (
      <Button
        sx={{px: 5, color: "primary", backgroundColor: "white" }}
        type="button" onClick={prev}>
        Previous
      </Button>
    )
  }
  // ...else return nothing
  return null
}

const nextButton = () => {
  let currentStep = step;
  // If the current step is not 3, then render the "next" button
  if(currentStep < stepCount && currentStep > 0){
    return (
      <Flex sx={{justifyContent: "flex-end", width: "100%"}}>
        <Button
          sx={{px: 5}} 
          type="button" onClick={next}>
          Next
        </Button>        
      </Flex>
    )
  } else if(currentStep === stepCount) {
    return (
      <Flex sx={{justifyContent: "flex-end", width: "100%"}}>
        <Button
          sx={{px: 5}}
          type="button" >
          Submit
        </Button>  
      </Flex>
    )
  }
  // ...else render nothing
  return null;
}



  return (
    <>
      <div sx={{width: "100%"}}>
        <Flex sx={{justifyContent: "space-between", alignItems: "center", px: 5, py: 4}}>
          <Styled.a as={Link} to="/" sx={{px: 4,mb: "0px", pb: "0px", fontSize: "0px"}}>
            <img src={logo} width="100%" sx={{mb: "0px", pb: "0px", width: ['75px', '100px']}} />
          </Styled.a>
          <Flex sx={{alignItems: "center", justifyContent: "center"}}>
            <div>
              <Styled.h4 sx={{my: "0px"}}>Special Offer</Styled.h4>
              <Styled.p sx={{my: "0px", py: "0px"}}>20% off your purchase!</Styled.p>
            </div>
          </Flex>
        </Flex>
        <Flex sx={{width: "100%", alignItems: "center", justifyContent: "center"}} >
          <progress id="file" max="100" sx={{width: "100%"}} value={step/stepCount * 100}> {step/stepCount * 100} </progress>
        </Flex>
      </div>
      <Flex sx={{flexWrap: "wrap", width: "100%"}} style={{minHeight: 'calc(100vh - 165px)'}}>
        <div sx={{width: "100%"}}>
          <Flex sx={{justifyContent: "center", alignItems: "center", height: "100%"}}>
            <Flex sx={{justifyContent: "center", alignItems: "center", width: ["100%","100%","50%"], px: 5}}>
              <Persona handleChange={handleChange} values={values} order={1} step={step} />
              <SleepType handleChange={handleChange} values={values} order={2} step={step} />
              <Temperature handleChange={handleChange} values={values} order={3} step={step} />
              <Comfort handleChange={handleChange} values={values} order={4} step={step} />
              <Name handleChange={handleChange} values={values} order={5} step={step} />
              <Weight handleChange={handleChange} values={values} order={6} step={step} />
              <Aches handleChange={handleChange} values={values} order={7} step={step} />
              <Concerns handleChange={handleChange} values={values} order={8} step={step} />
              <Email handleChange={handleChange} values={values} order={9} step={step} />
              <Results handleChange={handleChange} values={values} order={10} step={step} />
              <div sx={{position: "fixed", bottom: "0px", left: "0", right: "0", width: "100%", p: 5, backgroundColor: "#fafafa"}}>
                <Flex sx={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                  {previousButton()}
                  {nextButton()}       
                </Flex>
              </div>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </>
  )
}
