/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"

export const Persona = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition 
            classNames="slide" 
            timeout={3000}>
            <div>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
              <Styled.h2>Hi, who are you shopping for?</Styled.h2>
            </Flex>
            <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="me"
                name="persona"  
                type="radio"
                value="me"
                checked={values.persona === "me" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="me" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Just Me</span>
                </Flex>
              </label>
    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="couple"
                name="persona"
                type="radio"
                value="couple"
                checked={values.persona === "couple" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="couple" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>Me +1</span>
                </Flex>
              </label>
    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="chile"
                name="persona"
                type="radio"
                value="chile"
                checked={values.persona === "chile" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="chile" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>My Child</span>
                </Flex>
              </label>
    
              <input
                sx={{display: "none"}}
                className="checkbox"
                id="guest"
                name="persona"
                type="radio"
                value="guest"
                checked={values.persona === "guest" ? true : false}
                onChange={handleChange}
              />
              <label htmlFor="guest" sx={{width: ["100%"], textAlign: "center"}}>
                <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                  <span>My Guests</span>
                </Flex>
              </label>
            </Grid>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) 
      
}