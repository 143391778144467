/** @jsx jsx */
import { jsx, Flex, Styled} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";

export const Results = ({step, order, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])
  

  return (
    <TransitionGroup component={null}>
      {show && (
        <CSSTransition 
          classNames="slide" 
          timeout={3000}>
          <div>

          <Flex sx={{justifyCenter: "center", alignItems: "center", flexWrap: "wrap"}}>
            <Flex sx={{width: "100%", justifyContent: "center"}}>
              <Styled.h2>{values.first_name}, we've got the perfect system for you!</Styled.h2>
            </Flex>
            <div sx={{width: ["100%"], mt: 5}}>
              <Styled.p sx={{textAlign: "center"}}>You will receive an email from us shortly with your recommended sleep system!</Styled.p>           
            </div>
          </Flex> 
        </div>
          </CSSTransition>
          )
        }
      </TransitionGroup>
    ) 
      
}