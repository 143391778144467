/** @jsx jsx */
import { jsx, Flex, Styled, Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"

export const Weight = ({step, order, handleChange, values}) => {

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])

  

    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition 
            classNames="slide" 
            timeout={3000}>
        <div>
        <Flex sx={{width: "100%", justifyContent: "center"}}>
          <Styled.h2>Hey {values.first_name}, what is your weight?</Styled.h2>
        </Flex>
        <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="small"
            name="weight"
            type="radio"
            value="small"
            checked={values.weight === "small" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="small" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
         
              <span>Less than 150 lbs</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="medium"
            name="weight"
            type="radio"
            value="medium"
            checked={values.weight === "medium" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="medium" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
           
              <span>150 lbs to 184 lbs</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="large"
            name="weight"
            type="radio"
            value="large"
            checked={values.weight === "large" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="large"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
        
              <span>185 lbs or more</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="idk"
            name="weight"
            type="radio"
            value="idk"
            checked={values.weight === "idk" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="idk"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
          
              <span>No thanks</span>
            </Flex>
          </label>
     

        </Grid>
        </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) 
      
}