/** @jsx jsx */
import { jsx, Flex, Styled,Grid} from "theme-ui"
import React, {useState, useEffect} from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import curly from "../../images/curly.png"

export const SleepType = ({step, order, handleChange, values}) => {
  

  const [show, setShow] = useState(false)

  useEffect(()=> {
    if (step === order){
      setShow(!show)
    } else {
      setShow(false)
    }
  },[step])

  

    return (
      <TransitionGroup component={null}>
        {show && (
          <CSSTransition 
            classNames="slide" 
            timeout={3000}>
        <div>
        <Flex sx={{width: "100%", justifyContent: "center"}}>
          <Styled.h2>What is your main sleeping position?</Styled.h2>
        </Flex>

        <Grid columns={[2,2,4]} gap={5} sx={{width: "100%", mt: 5, justifyContent: "center"}}>
          <input
            sx={{display: "none"}}
            className="checkbox"
            id="side"
            name="sleeptype"
            type="radio"
            value="side"
            checked={values.sleeptype === "side" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="side" sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>

              <span>My side</span>
            </Flex>
          </label>
    
          <input
            sx={{display: "none"}}
            className="checkbox"
            id="back"
            name="sleeptype"
            type="radio"
            value="back"
            checked={values.sleeptype === "back" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="back"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
   
              <span>My back</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="front"
            name="sleeptype"
            type="radio"
            value="front"
            checked={values.sleeptype === "front" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="front"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
  
              <span>My belly</span>
            </Flex>
          </label>

          <input
            sx={{display: "none"}}
            className="checkbox"
            id="idk"
            name="sleeptype"
            type="radio"
            value="idk"
            checked={values.sleeptype === "idk" ? true : false}
            onChange={handleChange}
          />
          <label htmlFor="idk"sx={{width: ["100%"], textAlign: "center"}}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
      
              <span>I'm not sure</span>
            </Flex>
          </label>
      
        </Grid>
        </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    ) 
      
}